<template>
  <div class="privacy container">
    <template v-if="$i18n.locale === 'kh'">
      <h3>Privacy and Cookie Policy</h3>

      <h4>ឯកជនភាព</h4>
      គេហទំព័ររបស់ <strong>U-Pay (www.u-pay.com)</strong> និង
      <strong>U-Pay Wallet</strong>
      មានមុខងារក្នុងការប្រមូលព័ត៌មានផ្ទាល់ខ្លួនរបស់អ្នក។
      <strong>U-Pay</strong
      >នឹងប្រមូលព័ត៌មានទាំងអស់(ព័ត៌មានផ្ទាល់ខ្លួននិងព័ត៌មានគណនីរបស់អ្នក)
      ដែលចាំបាច់សម្រាប់ពួកយើងដើម្បីផ្តល់នូវដំណោះស្រាយទូទាត់ប្រាក់ជូនអ្នក។
      យើងនឹងរក្សាទុកព័ត៌មាននេះដោយមានសុវត្ថិភាពហើយនឹងប្រើវាក្នុងគោលបំណងសម្រាប់ទាក់ទងនិងកំណត់អត្តសញ្ញាណរបស់អ្នក។
      បន្ថែមពីនេះទៀត
      ព័ត៌មានដែលយើងរក្សាក៏អាចត្រូវយកមកប្រើសម្រាប់ផ្តល់ជូនអ្នកនូវព័ត៌មានថ្មីៗ
      អំពីគណនីនិងសេវាកម្មដែលអាចធ្វើឱ្យអ្នកចាប់អារម្មណ៍រួមមានក្រុមហ៊ុនដទៃរបស់យើង
      ធនាគារឧបត្ថម្ភ និង ដៃគូអាជីវកម្មដែលបានជ្រើសរើស។
      យើងនឹងគោរពតាមការស្នើសុំប្រសិនបើអ្នកស្នើសុំមិនផ្តល់នូវព័ត៌មានផ្ទាល់ខ្លួនទាំងនេះ។
      អ្នកមានសិទ្ធិក្នុងការចូលពិនត្យនិងកែតម្រូវព័ត៌មានរបស់អ្នក។
      យើងមិនតម្រូវឲ្យអ្នកចាំបាច់ត្រូវផ្តល់ឱ្យេយើងនូវព័ត៌មានដែលត្រូវបានស្នើសុំទោះទេ
      ក៏ប៉ុន្តែវាអាចប៉ះពាល់ដល់ប្រតិបត្តិការនៃផលិតផលឬសេវាកម្មណាមួយដែលយើងអាចផ្តល់ឱ្យអ្នក។
      យើងនឹងមិនចែកចាយឬលក់ព័ត៌មានរបស់អ្នកទៅឱ្យភាគីទីបីនោះទេ។

      <h4>Cookie</h4>
      <strong>U-Pay</strong>
      ប្រើប្រាស់បច្ចេកវិទ្យាមួយដែលអាចផ្តល់ជូនអ្នកនូវភាពងាយស្រួលលើសេវាកម្មពាក់ព័ន្ធជាច្រើន។
      ក្នុងនោះដែរយើងមានបច្ចេកវិទ្យាម្យ៉ាងដែលមានឈ្មោះថា៖
      “<strong>Cookie</strong>”។
      <strong>Cookie</strong>
      គឺជាកម្រងឯកសារដ៏តូចមួយដែលកំព្យួទ័រអ្នកបានរក្សាទុកនៅពេលដែលអ្នកចូលទៅកាន់គេហទំព័រណាមួយក្នុងអ៊ីនធឺណេត។
      រាល់ការចូលគេហទំព័រនីមួយៗដែលមានភ្ជាប់នូវប្រព័ន្ធ
      <strong>Cookie</strong>
      កម្មវីធីរុករកនឹងផ្ញើរទិន្នន័យទៅក្នុងបណ្តាញមេន័យគេហទំព័រនោះវិញ។
      មូលហេតុដែលយើងប្រើបច្ចេកវិទ្យានេះគឺមានច្រើនចំណុច។
      តែក្នុងនោះគឺសម្រាប់ផ្តល់នូវសុវត្ថិភាពរវាងអ្នកនិងបណ្តាញរបស់យើងនៅពេលដែលអ្នកចូលមកកាន់គេហទំព័ររបស់U-Pay។
      អ្នកអាចធ្វើកំណត់បានក្នុងការយល់ព្រមប្រើប្រាស់បច្ចេកវិទ្យានេះតាមរយៈកម្មវិធីរុករក។
      អ្នកអាចធ្វើការជ្រើសរើសមិនទទួលប្រើនូវប្រព័ន្ធ
      <strong>Cookie</strong> នៅលើគេហទំព័ររបស់យើងបាន។
      ក៏ប៉ន្តែអ្នកអាចនឹងបរាជ័យក្នុងការចូលប្រើប្រាស់សេវាកម្មរបស់យើងបានដោយសារមិនបានយល់ព្រមទទួលប្រើប្រព័ន្ធ<strong>Cookie</strong>ដែលបានចែងនៅខាងលើ។
    </template>
    <template v-else-if="$i18n.locale === 'zh'">
      <h3>隐私权政策和Cookie政策</h3>

      <h4>隐私权</h4>
      <strong>U-Pay</strong
      >的网站（www.u-pay.com）和<strong>U-Pay</strong>钱包功能模组包含收集您的个人信息。<strong>U-Pay</strong>将收集所有信息（包含个人资料和帐户信息）给于为您提供服务的相关服务者。
      我们将安全地保存信息，仅将其用于与您联系并用于识别目的。此外，此信息还可用于向您提供有关我们认为您可能感兴趣的帐户和服务的信息（包括与我们的母公司，赞助银行和我们选定的业务合作伙伴）。您将有权要求终止不要向您提供此信息，我们将达成您的要求。您有权访问以及更正您的信息。您有权不提供我们所要求的信息，当可能会影响我们可以为您提供的产品或服务。我们不会将您的信息出售给第三方。

      <h4>Cookie政策</h4>

      <strong>U-Pay</strong>应用技术，以便为您提供最便捷、最相关的服务。
      <strong>U-Pay</strong>使用的技术的称为“<strong>cookie</strong>”。
      Cookies是一小段文档，当您访问某些网站时，浏览器会将它们存储在电脑上。每次您访问我们的网站时，浏览器都会将存储的<strong>cookie</strong>中数据发送回该网站的服务器。
      我们使用<strong>cookie</strong>的用途包括：维护您使用我们的网站时浏览器与服务器之间的安全连接。
      您可能可以设置您的浏览器在提供<strong>cookie</strong>时通知您，并决定是否接受它。在浏览我们的网站时，您可以选择不接受<strong>cookie</strong>。但是，如果不接受上述<strong>cookie</strong>政策，您可能无法登录我们的安全服务。
    </template>
    <template v-else>
      <h3>Privacy and Cookie Policy</h3>
      <h4>Privacy</h4>
      U-Pay’s website (www.u-pay.com) and <strong>U-Pay</strong> Wallet contains
      modules that collect your personal information.
      <strong>U-Pay</strong> will collect all information (your profile and
      account information) for those that are necessary for us to deliver a
      payment solution to you. We will hold the information securely and will
      only use it for contacting you and in purpose of identification. Also this
      information may be used for providing you information about accounts and
      services that we think might be of interest to you (including with our
      parent company, sponsored bank and our selected business partners). If you
      ask us not to provide you with this information, we will comply with your
      request. You have rights of access to, and correction of, your
      information. You do not have to provide us with information we request,
      though it may affect which products or services we can provide you with.
      We will not sell your information to third parties.
      <h4>Cookie</h4>
      <strong>U-Pay</strong> uses technology in order to offer you the most
      convenient and relevant services. An example of the technology that
      <strong>U-Pay</strong> uses is a "<strong>cookie</strong>". Cookies are
      very small text files that your browser stores on your computer when you
      visit certain websites. Every time you visit a website that has issued a
      cookie to your computer, your browser sends the data that it has stored in
      that <strong>cookie</strong> back to the website's server. We use cookies
      for a number of purposes, including to maintain the secure connection
      between your browser and our servers while you are using our secure
      websites. You may be able to configure your browser to notify you when you
      are offered a <strong>cookie</strong> and decide whether or not to accept
      it. You may choose not to accept cookies when browsing our websites.
      However, you may be unable to log on to our secure services without
      accepting the cookies outlined above.
    </template>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
.privacy {
  &.container {
    margin: 0 auto;
    padding: 20px;
    max-width: 1000px;
  }
  h3 {
    font-size: 28px;
    font-weight: bold;
    padding: 20px 0;
  }
  h4 {
    font-size: 20px;
    font-weight: bold;
    padding: 15px 0;
  }
  strong {
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
